import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/myAvatar.svg";
import Tilt from "react-parallax-tilt";
import { AiFillGithub, AiOutlineTwitter } from "react-icons/ai";
import { FaLinkedinIn, FaPhoneAlt } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { Link } from "react-router-dom";

function Home2() {
  const mailTo = (event) => {
    event.preventDefault();
    window.location.href = `mailto:mayckell-perez@hotmail.com`;
  };
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "3em" }}>
              <span className="purple"> A little </span> about me
            </h1>
            <p className="home-about-body">
              I've become smitten with programming, and I've definitely picked
              up a thing or two, I reckon...🤷‍♂️
              <br />
              <br />
              I'm a young enthusiast of computer programming, always eager to
              tackle &nbsp;
              <i>
                <b className="purple">
                  new challenges and equipped with the skills to overcome them.
                </b>
              </i>
              <br />
              <br />
              Is it just me, or is there something thrilling about bringing
              ideas or tasks to life through code?
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/MayckellP"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/mayckell-perez/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <Link
                  to="tel:+41782484535"
                  className="icon-colour  home-social-icons"
                >
                  <FaPhoneAlt />
                </Link>
              </li>
              <li className="social-icons">
                <a
                  href="/"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  onClick={mailTo}
                >
                  <IoMail />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;

import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import WorkIcon from "@mui/icons-material/Work";
import SchoolIcon from "@mui/icons-material/School";

export default function TimelineSkills() {
  return (
    <Timeline position="alternate">
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0", fontSize: 25, fontWeight: "bold" }}
          align="right"
          variant="body2"
          color="#70a3f0"
        >
          2018
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot sx={{ width: 50, height: 50, background: "white" }}>
            <WorkIcon
              sx={{ fontSize: 35, m: "auto", color: "rgb(137, 92, 24)" }}
            />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span" className="fw-bold">
            Gusber SA - System Assistant
          </Typography>
          <Typography sx={{ fontSize: 15 }}>
            Development of technological processes for the benefit of the
            company.
          </Typography>
          <Typography>
            <i>2018 - 2022</i>
          </Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0", fontSize: 25, fontWeight: "bold" }}
          variant="body2"
          color="#70a3f0"
        >
          2019
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot
            color="primary"
            sx={{ width: 50, height: 50, background: "white" }}
          >
            <SchoolIcon sx={{ fontSize: 35, m: "auto", color: "#c93628" }} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span" className="fw-bold">
            1000Soluciones, Ec
          </Typography>
          <Typography sx={{ fontSize: 15 }}>
            Microeletronic - Continuing education.
          </Typography>
          <Typography>
            <i>4 Months</i>
          </Typography>
          <br />
          <Typography variant="h6" component="span" className="fw-bold">
            INCAPEM, Ec
          </Typography>
          <Typography sx={{ fontSize: 15 }}>
            Electronic Security - Continuing education.
          </Typography>
          <Typography>
            <i>5 Months</i>
          </Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0", fontSize: 25, fontWeight: "bold" }}
          align="right"
          variant="body2"
          color="#70a3f0"
        >
          2020
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot sx={{ width: 50, height: 50, background: "white" }}>
            <SchoolIcon sx={{ fontSize: 35, m: "auto", color: "#c93628" }} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span" className="fw-bold">
            Bolivarian University Institute
          </Typography>
          <Typography sx={{ fontSize: 15 }}>
            Software developer - 2 Semester.
          </Typography>
          <Typography>
            <i>2019 - 2020</i>
          </Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0", fontSize: 25, fontWeight: "bold" }}
          variant="body2"
          color="#70a3f0"
        >
          2021
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot
            color="primary"
            sx={{ width: 50, height: 50, background: "white" }}
          >
            <SchoolIcon sx={{ fontSize: 35, m: "auto", color: "#c93628" }} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span" className="fw-bold">
            Cisco Academy
          </Typography>
          <Typography sx={{ fontSize: 15 }}>
            IT Essential Security - Continuing education.
          </Typography>
          <Typography>
            <i>3 Months</i>
          </Typography>
          <br />
          <Typography variant="h6" component="span" className="fw-bold">
            CETEC, Ec
          </Typography>
          <Typography sx={{ fontSize: 15 }}>
            Programming with Java - Continuing education.
          </Typography>
          <Typography>
            <i>3 Months</i>
          </Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0", fontSize: 25, fontWeight: "bold" }}
          align="right"
          variant="body2"
          color="#70a3f0"
        >
          2022
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot sx={{ width: 50, height: 50, background: "white" }}>
            <SchoolIcon sx={{ fontSize: 35, m: "auto", color: "#c93628" }} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span" className="fw-bold">
            Ucamp, Mx
          </Typography>
          <Typography sx={{ fontSize: 15 }}>
            Utel University - Bootcamp Fullstack MERN.
          </Typography>
          <Typography>
            <i>1 Year</i>
          </Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0", fontSize: 25, fontWeight: "bold" }}
          variant="body2"
          color="#70a3f0"
        >
          2023
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot
            color="primary"
            sx={{ width: 50, height: 50, background: "white" }}
          >
            <SchoolIcon sx={{ fontSize: 35, m: "auto", color: "#c93628" }} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span" className="fw-bold">
            Opportunity, Zh
          </Typography>
          <Typography sx={{ fontSize: 15 }}>
            Bootcamp oriented programming with PHP - Laravel (German).
          </Typography>
          <Typography>
            <i>9 Month</i>
          </Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0", fontSize: 25, fontWeight: "bold" }}
          align="right"
          variant="body2"
          color="#70a3f0"
        >
          2024
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot sx={{ width: 50, height: 50, background: "#5f69f8" }}>
            <SchoolIcon sx={{ fontSize: 35, m: "auto", color: "white" }} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span" className="fw-bold">
            ZHAW, Zh
          </Typography>
          <Typography sx={{ fontSize: 15 }}>Software Engineer.</Typography>
          <Typography>
            <i>2024 - 2028</i>
          </Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import arn from "../../Assets/Projects/arn.svg";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={arn}
              isBlog={false}
              title="Interactive Electoral Platform (IEP)"
              description="The Interactive Electoral Platform (IEP) is a web application designed to organize and manage elections at the departmental and district levels within a political party. With defined user roles, intuitive performance graphs, and voter management features, the IEP provides a comprehensive solution to drive participation and transparency in the electoral process."
              ghLink="https://github.com/MayckellP/interactive-electoral-platform"
              demoLink="https://anr-app.online/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
